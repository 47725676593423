import { Link } from 'react-router-dom';
import AtButton from '../../components/AtButton';
import NotFoundImage from '../../images/404.svg';

const NotFound = () => {
	return (
		<div className='bg-primary h-screen flex items-center justify-center flex-col'>
			<img
				src={NotFoundImage}
				alt='404 vector'
				className='max-w-[375px] md:max-w-[450px]'
			/>
			<div className='text-white text-3xl lg:text-4xl'>Page not found</div>

			<Link to='/'>
				<AtButton
					variant='secondary'
					className='mt-4 text-lg lg:text-xl font-medium'
				>
					Back to home
				</AtButton>
			</Link>

			<div></div>
		</div>
	);
};

export default NotFound;
