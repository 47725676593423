import React, { SVGProps } from 'react';

const FacebookLogo = ({
	height = 8,
	width = 13,
	fill = '#818181',
}: SVGProps<SVGElement>) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 8 13'
			fill='none'
		>
			<path
				id='Vector'
				d='M4.90585 12.3265H2.7499V7.03219H0.94873V4.95812H2.7499V3.42985C2.7499 2.55656 2.99551 1.88339 3.48674 1.41036C3.97797 0.937325 4.63294 0.700807 5.45165 0.700807C6.10663 0.700807 6.64334 0.728098 7.06179 0.782679V2.61114L5.94288 2.63843C5.54262 2.63843 5.26972 2.7203 5.12417 2.88404C4.97862 3.04779 4.90585 3.2934 4.90585 3.62088V4.95812H6.97992L6.70702 7.03219H4.90585V12.3265Z'
				fill={fill}
				fillOpacity='0.4'
			/>
		</svg>
	);
};

export default FacebookLogo;
