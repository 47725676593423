import { createBrowserRouter } from 'react-router-dom';

// Pages
import Home from '../views/Home';
import Dashboard from '../views/Dashboard';
import NotFound from '../views/NotFound';
import PrivateRoutes from './PrivateRoute';

// Aqui se agregaran la lista de paginas de la aplicacion

export const router = createBrowserRouter([
	{
		path: '/',
		element: <Home />,
	},

	{
		element: <PrivateRoutes />,
		children: [{ path: '/dashboard/:id?', element: <Dashboard /> }],
	},
	{ path: '*', element: <NotFound /> },
]);
