import React, { SVGProps } from 'react';

const TwitterLogo = ({
	height = 12,
	width = 11,
	fill = '#818181',
}: SVGProps<SVGElement>) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 12 11'
			fill='none'
		>
			<path
				id='Vector'
				d='M10.631 3.13935C10.631 3.24851 10.631 3.34857 10.631 3.43954C10.631 4.25826 10.4809 5.06332 10.1807 5.85475C9.88047 6.64617 9.45292 7.36937 8.89801 8.02434C8.3431 8.67931 7.62445 9.21147 6.74206 9.62083C5.85967 10.0302 4.89086 10.2349 3.83563 10.2349C2.5075 10.2349 1.28852 9.88009 0.178711 9.17054C0.360647 9.18873 0.55168 9.19783 0.75181 9.19783C1.86162 9.19783 2.85317 8.86124 3.72647 8.18808C3.19885 8.16989 2.73037 8.00614 2.32101 7.69685C1.91165 7.38756 1.6342 6.9964 1.48865 6.52336C1.6342 6.55975 1.77975 6.57794 1.9253 6.57794C2.14362 6.57794 2.35285 6.54156 2.55298 6.46878C2.00717 6.35962 1.55233 6.08672 1.18846 5.65007C0.824585 5.21342 0.642649 4.7131 0.642649 4.14909C0.642649 4.1309 0.642649 4.1218 0.642649 4.1218C0.988328 4.30374 1.3522 4.4038 1.73427 4.422C1.02471 3.93077 0.669939 3.2667 0.669939 2.42979C0.669939 1.99315 0.779101 1.59289 0.997424 1.22902C1.59781 1.95676 2.32101 2.53896 3.16702 2.9756C4.01302 3.41225 4.92725 3.65787 5.90971 3.71245C5.87332 3.53051 5.85512 3.34857 5.85512 3.16664C5.85512 2.51167 6.08709 1.95221 6.55103 1.48827C7.01497 1.02434 7.57442 0.792368 8.22939 0.792368C8.92075 0.792368 9.50295 1.03798 9.97598 1.52921C10.5218 1.42005 11.0312 1.22902 11.5042 0.956111C11.3223 1.52011 10.9675 1.95676 10.4399 2.26605C10.9311 2.21147 11.3951 2.09321 11.8317 1.91128C11.5042 2.38431 11.104 2.79367 10.631 3.13935Z'
				fill={fill}
				fillOpacity='0.4'
			/>
		</svg>
	);
};

export default TwitterLogo;
