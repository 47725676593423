import React, { SVGProps } from 'react';

const TrashCan = ({
	width = 25,
	height = 25,
	fill = '#FF0000',
}: SVGProps<SVGElement>) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 25 25'
			fill='none'
		>
			<path
				d='M7.2998 4.5C7.2998 3.96957 7.51052 3.46086 7.88559 3.08579C8.26066 2.71071 8.76937 2.5 9.2998 2.5H15.2998C15.8302 2.5 16.3389 2.71071 16.714 3.08579C17.0891 3.46086 17.2998 3.96957 17.2998 4.5V6.5H21.2998C21.565 6.5 21.8194 6.60536 22.0069 6.79289C22.1944 6.98043 22.2998 7.23478 22.2998 7.5C22.2998 7.76522 22.1944 8.01957 22.0069 8.20711C21.8194 8.39464 21.565 8.5 21.2998 8.5H20.2308L19.3638 20.642C19.3279 21.1466 19.1021 21.6188 18.7319 21.9636C18.3617 22.3083 17.8747 22.5 17.3688 22.5H7.2298C6.72394 22.5 6.23687 22.3083 5.86669 21.9636C5.49651 21.6188 5.27072 21.1466 5.2348 20.642L4.3698 8.5H3.2998C3.03459 8.5 2.78023 8.39464 2.5927 8.20711C2.40516 8.01957 2.2998 7.76522 2.2998 7.5C2.2998 7.23478 2.40516 6.98043 2.5927 6.79289C2.78023 6.60536 3.03459 6.5 3.2998 6.5H7.2998V4.5ZM9.2998 6.5H15.2998V4.5H9.2998V6.5ZM6.3738 8.5L7.2308 20.5H17.3698L18.2268 8.5H6.3738ZM10.2998 10.5C10.565 10.5 10.8194 10.6054 11.0069 10.7929C11.1944 10.9804 11.2998 11.2348 11.2998 11.5V17.5C11.2998 17.7652 11.1944 18.0196 11.0069 18.2071C10.8194 18.3946 10.565 18.5 10.2998 18.5C10.0346 18.5 9.78023 18.3946 9.5927 18.2071C9.40516 18.0196 9.2998 17.7652 9.2998 17.5V11.5C9.2998 11.2348 9.40516 10.9804 9.5927 10.7929C9.78023 10.6054 10.0346 10.5 10.2998 10.5ZM14.2998 10.5C14.565 10.5 14.8194 10.6054 15.0069 10.7929C15.1944 10.9804 15.2998 11.2348 15.2998 11.5V17.5C15.2998 17.7652 15.1944 18.0196 15.0069 18.2071C14.8194 18.3946 14.565 18.5 14.2998 18.5C14.0346 18.5 13.7802 18.3946 13.5927 18.2071C13.4052 18.0196 13.2998 17.7652 13.2998 17.5V11.5C13.2998 11.2348 13.4052 10.9804 13.5927 10.7929C13.7802 10.6054 14.0346 10.5 14.2998 10.5Z'
				fill={fill}
			/>
		</svg>
	);
};

export default TrashCan;
