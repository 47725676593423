import React, { SVGProps } from 'react';

const ArrowRight = ({
	width = 20,
	height = 20,
	fill = '#023865',
}: SVGProps<SVGElement>) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 20 20'
			fill='none'
		>
			<path
				d='M16.667 10L17.1973 9.46967L17.7277 10L17.1973 10.5303L16.667 10ZM4.16699 10.75C3.75278 10.75 3.41699 10.4142 3.41699 10C3.41699 9.58579 3.75278 9.25 4.16699 9.25V10.75ZM12.1973 4.46967L17.1973 9.46967L16.1367 10.5303L11.1367 5.53033L12.1973 4.46967ZM17.1973 10.5303L12.1973 15.5303L11.1367 14.4697L16.1367 9.46967L17.1973 10.5303ZM16.667 10.75H4.16699V9.25H16.667V10.75Z'
				fill={fill}
			/>
		</svg>
	);
};

export default ArrowRight;
