import { DashboardLayoutProps } from './types';
import { AtIcon } from '../../components/AtIcon';
import MlSidebar from './components/MlSidebar';
import { useDashboardContext } from '../../context/DashboardsProvider';
import { useParams } from 'react-router-dom';

const DashboardLayout = ({ children }: DashboardLayoutProps) => {
	const dashboardsContext = useDashboardContext();
	const { dashboards } = dashboardsContext;
	const { id } = useParams();

	return (
		<>
			<div className='lg:hidden bg-primary h-full min-h-screen overflow-hidden flex items-center justify-center flex-col'>
				<div className='p-4 text-lg md:text-4xl text-white text-center'>
					This content can only be accessed from a tablet device or higher.
				</div>
				<div className='p-4 text-lg md:text-4xl text-white text-center'>
					If you're using a tablet, please rotate your device to landscape mode.
				</div>
				<div className='text-center mt-4'>
					<AtIcon
						type='tablet-landscape'
						width={100}
						height={100}
						fill='white'
					/>
				</div>
			</div>

			<div className='content hidden lg:block'>
				<div className='flex h-full min-h-screen'>
					<MlSidebar dashbboards={dashboards} dashboardId={id} />
					<div className='content bg-gray-200 min-w-[80%] w-full '>
						{children}
					</div>
				</div>
			</div>
		</>
	);
};

export default DashboardLayout;
