import { Navigate, Outlet } from 'react-router-dom';
import Cookies from 'universal-cookie';

const PrivateRoutes = () => {
	const cookies = new Cookies();
	const token = cookies.get('jwt_token');

	return token ? <Outlet /> : <Navigate to='/' replace />;
};

export default PrivateRoutes;
