import React, { SVGProps } from 'react';

const Alert = ({
	width = 24,
	height = 25,
	fill = '#0A8A26',
}: SVGProps<SVGElement>) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 24 25'
			fill='none'
		>
			<path
				d='M12 17.5C12.2833 17.5 12.521 17.404 12.713 17.212C12.905 17.02 13.0007 16.7827 13 16.5C13 16.2167 12.904 15.979 12.712 15.787C12.52 15.595 12.2827 15.4993 12 15.5C11.7167 15.5 11.479 15.596 11.287 15.788C11.095 15.98 10.9993 16.2173 11 16.5C11 16.7833 11.096 17.021 11.288 17.213C11.48 17.405 11.7173 17.5007 12 17.5ZM11 13.5H13V7.5H11V13.5ZM12 22.5C10.6167 22.5 9.31667 22.2373 8.1 21.712C6.88333 21.1867 5.825 20.4743 4.925 19.575C4.025 18.675 3.31267 17.6167 2.788 16.4C2.26333 15.1833 2.00067 13.8833 2 12.5C2 11.1167 2.26267 9.81667 2.788 8.6C3.31333 7.38333 4.02567 6.325 4.925 5.425C5.825 4.525 6.88333 3.81267 8.1 3.288C9.31667 2.76333 10.6167 2.50067 12 2.5C13.3833 2.5 14.6833 2.76267 15.9 3.288C17.1167 3.81333 18.175 4.52567 19.075 5.425C19.975 6.325 20.6877 7.38333 21.213 8.6C21.7383 9.81667 22.0007 11.1167 22 12.5C22 13.8833 21.7373 15.1833 21.212 16.4C20.6867 17.6167 19.9743 18.675 19.075 19.575C18.175 20.475 17.1167 21.1877 15.9 21.713C14.6833 22.2383 13.3833 22.5007 12 22.5Z'
				fill={fill}
			/>
		</svg>
	);
};

export default Alert;
