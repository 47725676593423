import React, { SVGProps } from 'react';

const HamburguerMenu = ({
	width = 32,
	height = 32,
	fill = 'black',
}: SVGProps<SVGElement>) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 32 32'
			fill='none'
		>
			<g id='material-symbols:menu'>
				<path
					id='Vector'
					d='M4 24V21.3333H28V24H4ZM4 17.3333V14.6667H28V17.3333H4ZM4 10.6667V8H28V10.6667H4Z'
					fill={fill}
				/>
			</g>
		</svg>
	);
};

export default HamburguerMenu;
