import { useEffect, useState } from 'react';
import DashboardLayout from '../../layouts/DashboardLayout';

import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { useDashboardContext } from '../../context/DashboardsProvider';
import { useNavigate, useParams } from 'react-router-dom';
import { Dashboard as DashboardProps } from '../../context/DashboardsProvider/types';
import { getSelectedDashboard } from '../../utils/getSelectedDashboard';
import { DASHBOARD_HTTPS_CODES } from './types';

const Dashboard = () => {
	const dashboardsContext = useDashboardContext();
	const { dashboards, isLoading, getDashboards } = dashboardsContext;
	const { id } = useParams();
	const [selectedDashboard, setSelectedDashboard] = useState<DashboardProps>();
	const navigate = useNavigate();

	// Handlers
	const handleSelectedDashboard = (dashboards: any) => {
		setSelectedDashboard(
			id === undefined
				? dashboards[0]
				: getSelectedDashboard(dashboards, id?.toString() ?? '0')
		);
	};

	// Busca y selecciona el dashboard respectivo cuando se entra a la página
	useEffect(() => {
		getDashboards().then((data: any) => {
			const { status } = data;

			if (status === DASHBOARD_HTTPS_CODES.INVALID_TOKEN) {
				navigate('/');
			}

			handleSelectedDashboard(data?.data);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Selecciona el respectivo dashboard cuando cambia el ID
	useEffect(() => {
		handleSelectedDashboard(dashboards);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	return (
		<DashboardLayout>
			<div className='dashboard'>
				{isLoading ? (
					<div className='h-screen w-full flex items-center justify-center'>
						{' '}
						Loading...
					</div>
				) : (
					<>
						{selectedDashboard ? (
							<PowerBIEmbed
								embedConfig={{
									type: 'report',
									embedUrl: selectedDashboard?.report?.url,
									accessToken: selectedDashboard?.embedToken,
									tokenType: models.TokenType.Embed,
									settings: {
										panes: {
											filters: {
												expanded: false,
												visible: false,
											},
										},
									},
								}}
								cssClassName={'powerbi-style'}
							/>
						) : (
							<div className='h-screen w-full flex items-center justify-center'>
								No dashboard available
							</div>
						)}
					</>
				)}
			</div>
		</DashboardLayout>
	);
};

export default Dashboard;
