import React, { SVGProps } from 'react';

const SearchOutlined = ({
	width = 18,
	height = 18,
	fill = 'white',
}: SVGProps<SVGElement>) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 18 18'
			fill='none'
		>
			<g id='simple-line-icons:magnifier' clipPath='url(#clip0_252_2078)'>
				<path
					id='Vector'
					d='M17.8358 17.0339L12.3703 11.5349C13.389 10.3148 14.0029 8.74517 14.0029 7.03123C14.0029 3.14802 10.8549 -1.52588e-05 6.97168 -1.52588e-05C3.08846 -1.52588e-05 -0.0595703 3.14802 -0.0595703 7.03123C-0.0595703 10.9145 3.08846 14.0625 6.97168 14.0625C8.73596 14.0625 10.3475 13.4111 11.5822 12.3373L17.0401 17.8295C17.2598 18.0492 17.6162 18.0492 17.8358 17.8295C18.0552 17.6099 18.0552 17.2538 17.8358 17.0339H17.8358ZM6.97168 12.9468C3.70468 12.9468 1.05615 10.2982 1.05615 7.03123C1.05615 3.76423 3.70468 1.1157 6.97168 1.1157C10.2387 1.1157 12.8872 3.76423 12.8872 7.03123C12.8872 10.2982 10.2387 12.9468 6.97168 12.9468Z'
					fill='#818181'
				/>
			</g>
			<defs>
				<clipPath id='clip0_252_2078'>
					<rect width='18' height='18' fill={fill} />
				</clipPath>
			</defs>
		</svg>
	);
};

export default SearchOutlined;
