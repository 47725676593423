import { AtButtonVariants } from './types';

const buttonClasses = 'rounded-lg  text-sm';

export const getButtonVariant = (variant: string) => {
	switch (variant) {
		case AtButtonVariants.PRIMARY:
			return `bg-primary text-white text-xs md:text-base py-2.5 px-4 font-semibold ${buttonClasses}`;
		case AtButtonVariants.SECONDARY:
			return `bg-secondary text-primary py-2 px-8 font-medium ${buttonClasses}`;
		case AtButtonVariants.PRIMARY_OUTLINED:
			return `bg-white border border-primary text-primary py-1 px-4 font-medium ${buttonClasses} `;
		default:
			return `bg-primary text-white ${buttonClasses}`;
	}
};
