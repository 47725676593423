import { useNavigate } from 'react-router-dom';
import AtButton from '../../components/AtButton';
import LoginVector from '../../images/medical_image.svg';
import MicrosoftLogin from 'react-microsoft-login';
import DatavisionLogoFull from '../../images/datavision-logo-full.svg';
import { LOGIN_HTTP_CODES, MicrosoftLoginDataProps } from './types';
import { fetchWithoutToken } from '../../utils/fetch';

import Cookies from 'universal-cookie';
import { transformEpochToDate } from '../../utils/transformEpochToDate';

const Home = () => {
	const navigate = useNavigate();
	const cookies = new Cookies();

	const loginUser = async (userData: any) => {
		const res = await fetchWithoutToken('/login', userData, 'POST');

		if (res?.status === LOGIN_HTTP_CODES.USER_NOT_EXIST) {
			return alert('USER NOT FOUND');
		}

		if (res?.status === LOGIN_HTTP_CODES.TOKEN_EXPIRED) {
			return alert('TOKEN EXPIRED');
		}

		if (res?.status === LOGIN_HTTP_CODES.SUCCESS) {
			const { access_token, expiration_time } = res?.response;

			cookies.set('jwt_token', access_token, {
				expires: transformEpochToDate(expiration_time),
			});
			navigate('/dashboard');
		}
	};
	// Handlers
	const loginHandler = async (err: any, data: MicrosoftLoginDataProps) => {
		if (!err && data) {
			loginUser(data?.account);
		}
	};

	return (
		<div className='container-admin flex items-center'>
			<div className='hidden image lg:w-2/3 lg:flex justify-center items-center bg-[#F8F8F8] h-screen'>
				<img src={LoginVector} alt='People in a meeting' />
			</div>
			<div className='h-full min-h-screen flex items-center justify-center flex-col  w-full lg:w-1/3 px-6'>
				<div className='logo mb-8'>
					<img
						src={DatavisionLogoFull}
						alt='CMVC logo'
						className='max-w-[12.5rem] w-full'
					/>
				</div>
				{/* <button onClick={handleLogin}>Test</button> */}
				<AtButton className='w-full max-w-[21.5rem]'>
					<MicrosoftLogin
						clientId={process.env.REACT_APP_MICROSOFT_CLIENTID || ''}
						authCallback={loginHandler}
					>
						<span>Login with Microsoft</span>
					</MicrosoftLogin>
				</AtButton>
			</div>
		</div>
	);
};

export default Home;
