import React, { SVGProps } from 'react';

const ChevronDown = ({
	height = 20,
	width = 20,
	fill = '#023865',
	className,
}: SVGProps<SVGElement>) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={height}
			height={height}
			viewBox='0 0 20 21'
			fill='none'
		>
			<g id='keyboard_arrow_down' clipPath='url(#clip0_330_1110)'>
				<path
					id='Vector'
					d='M6.175 7.65834L10 11.475L13.825 7.65834L15 8.83334L10 13.8333L5 8.83334L6.175 7.65834Z'
					fill={fill}
					className={className}
				/>
			</g>
			<defs>
				<clipPath id='clip0_330_1110'>
					<rect
						width={width}
						height={height}
						fill='white'
						transform='translate(0 0.5)'
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default ChevronDown;
