import React, { SVGProps } from 'react';

const UserProfile = ({
	height = 25,
	width = 24,
	fill = 'white',
	className,
}: SVGProps<SVGElement>) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 24 25'
			fill='none'
		>
			<path
				d='M12 4.5C13.0609 4.5 14.0783 4.92143 14.8284 5.67157C15.5786 6.42172 16 7.43913 16 8.5C16 9.56087 15.5786 10.5783 14.8284 11.3284C14.0783 12.0786 13.0609 12.5 12 12.5C10.9391 12.5 9.92172 12.0786 9.17157 11.3284C8.42143 10.5783 8 9.56087 8 8.5C8 7.43913 8.42143 6.42172 9.17157 5.67157C9.92172 4.92143 10.9391 4.5 12 4.5ZM12 14.5C16.42 14.5 20 16.29 20 18.5V20.5H4V18.5C4 16.29 7.58 14.5 12 14.5Z'
				fill={fill}
				className={className}
			/>
		</svg>
	);
};

export default UserProfile;
