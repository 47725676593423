import React from 'react';
import { IconType } from './types';

// Icons Components
import WorldGlobe from './icons/WorldGlobe';
import UserProfile from './icons/UserProfile';
import ChevronDown from './icons/ChevronDown';
import RestartArrows from './icons/RestartArrows';
import Profile from './icons/Profile';
import Logout from './icons/Logout';
import AlertRounded from './icons/AlertRounded';
import EyeOpen from './icons/EyeOpen';
import Close from './icons/Close';
import TwitterLogo from './icons/TwitterLogo';
import InstagramLogo from './icons/InstagramLogo';
import FacebookLogo from './icons/FacebookLogo';
import LinkedinLogo from './icons/LinkedinLogo';
import YoutubeLogo from './icons/YoutubeLogo';
import TikTokLogo from './icons/TikTokLogo';
import House from './icons/House';
import Paper from './icons/Paper';
import Grid from './icons/Grid';
import QuestionMark from './icons/QuestionMark';
import UserOutline from './icons/UserOutline';
import Check from './icons/Check';
import HamburguerMenu from './icons/HamburguerMenu';
import ArrowLeft from './icons/ArrowLeft';
import ArrowRight from './icons/ArrowRight';
import Add from './icons/Add';
import Alert from './icons/Alert';
import SearchOutlined from './icons/SearchOutlined';
import EditPencil from './icons/EditPencil';
import TrashCan from './icons/TrashCan';
import Save from './icons/Save';
import TabletLandscape from './icons/TabletLandscape';

export interface AtIconProps extends React.SVGProps<SVGSVGElement> {
	type: IconType;
}

const icons: Record<
	IconType,
	(props: React.SVGProps<SVGSVGElement>) => JSX.Element
> = {
	'world-globe': (props) => <WorldGlobe {...props} />,
	'user-profile': (props) => <UserProfile {...props} />,
	'chevron-down': (props) => <ChevronDown {...props} />,
	'restart-arrows': (props) => <RestartArrows {...props} />,
	profile: (props) => <Profile {...props} />,
	logout: (props) => <Logout {...props} />,
	'alert-rounded': (props) => <AlertRounded {...props} />,
	'eye-open': (props) => <EyeOpen {...props} />,
	close: (props) => <Close {...props} />,
	'twitter-logo': (props) => <TwitterLogo {...props} />,
	'instagram-logo': (props) => <InstagramLogo {...props} />,
	'facebook-logo': (props) => <FacebookLogo {...props} />,
	'linkedin-logo': (props) => <LinkedinLogo {...props} />,
	'youtube-logo': (props) => <YoutubeLogo {...props} />,
	'tiktok-logo': (props) => <TikTokLogo {...props} />,
	house: (props) => <House {...props} />,
	paper: (props) => <Paper {...props} />,
	grid: (props) => <Grid {...props} />,
	'question-mark': (props) => <QuestionMark {...props} />,
	'user-outline': (props) => <UserOutline {...props} />,
	check: (props) => <Check {...props} />,
	'hamburger-menu': (props) => <HamburguerMenu {...props} />,
	'arrow-left': (props) => <ArrowLeft {...props} />,
	'arrow-right': (props) => <ArrowRight {...props} />,
	add: (props) => <Add {...props} />,
	alert: (props) => <Alert {...props} />,
	'search-outlined': (props) => <SearchOutlined {...props} />,
	'edit-pencil': (props) => <EditPencil {...props} />,
	trashcan: (props) => <TrashCan {...props} />,
	save: (props) => <Save {...props} />,
	'tablet-landscape': (props) => <TabletLandscape {...props} />,
};

export const AtIcon: React.FC<AtIconProps> = ({ type, ...restProps }) => {
	const icon = icons[type];
	if (icon) return icon(restProps);
	return null;
};
