import './App.css';
import { RouterProvider } from 'react-router-dom';
import { router } from './routes';
import { DashboardProvider } from './context/DashboardsProvider';

function App() {
	return (
		<>
			<DashboardProvider>
				<RouterProvider router={router}></RouterProvider>
			</DashboardProvider>
		</>
	);
}

export default App;
