import Cookies from 'universal-cookie';

const BASE_URL = process.env.REACT_APP_API_URL;

export const fetchWithoutToken = async (
	endpoint: string,
	data?: unknown,
	method = 'GET',
	headers?: any,
	signal?: AbortSignal
) => {
	const url = `${BASE_URL}${endpoint}`;

	if (method === 'GET') {
		const response = await fetch(url, {
			headers: { ...headers },
			signal,
		});
		return await response.json();
	}

	const response = await fetch(url, {
		method,
		headers: {
			...headers,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(data),
		signal,
	});

	if (method === 'DELETE') {
		const text = await response.text();
		if (!text) {
			return false;
		}
	}

	return { response: await response.json(), status: response.status };
};

export const fetchWithToken = async (
	endpoint: string,
	data?: unknown,
	method = 'GET',
	headers?: any,
	signal?: AbortSignal
) => {
	const cookies = new Cookies();
	const url = `${BASE_URL}${endpoint}`;
	const token = `Bearer ${cookies.get('jwt_token')}` || '';

	if (method === 'GET') {
		const response = await fetch(url, {
			headers: {
				...headers,
				Authorization: token,
			},
			signal,
		});
		return {
			data: await response.json(),
			status: response?.status,
		};
	}

	const response = await fetch(url, {
		method,
		headers: {
			Authorization: token,
			...headers,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(data),
		signal,
	});

	return {
		data: await response.json(),
		status: response?.status,
	};
};
