import React, { SVGProps } from 'react';

const RestartArrows = ({
	height = 20,
	width = 20,
	fill = '#818181',
	className,
}: SVGProps<SVGElement>) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 24 24'
			fill='none'
		>
			<g id='repeat' clipPath='url(#clip0_392_734)'>
				<path
					id='Vector'
					d='M7 7H17V10L21 6L17 2V5H5V11H7V7ZM17 17H7V14L3 18L7 22V19H19V13H17V17Z'
					fill={fill}
					fillOpacity='0.4'
					className={className}
				/>
			</g>
			<defs>
				<clipPath id='clip0_392_734'>
					<rect width='24' height='24' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};

export default RestartArrows;
