import React, { SVGProps } from 'react';

const Grid = ({
	width = 20,
	height = 20,
	fill = 'none',
}: SVGProps<SVGElement>) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 20 20'
			fill={fill}
		>
			<g id='Frame'>
				<path
					id='Vector'
					d='M8.33333 2.5H2.5V8.33333H8.33333V2.5Z'
					stroke='white'
					strokeWidth='1.16667'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					id='Vector_2'
					d='M17.5001 2.5H11.6667V8.33333H17.5001V2.5Z'
					stroke='white'
					strokeWidth='1.16667'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					id='Vector_3'
					d='M17.5001 11.6667H11.6667V17.5H17.5001V11.6667Z'
					stroke='white'
					strokeWidth='1.16667'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					id='Vector_4'
					d='M8.33333 11.6667H2.5V17.5H8.33333V11.6667Z'
					stroke='white'
					strokeWidth='1.16667'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
			</g>
		</svg>
	);
};

export default Grid;
