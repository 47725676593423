import { getButtonVariant } from './classes';
import { AtButtonPropsElement, AtButtonVariants } from './types';

const AtButton = ({
	children,
	variant = AtButtonVariants.PRIMARY,
	type = 'button',
	styles,
	onClick,
	disabled = false,
	className,
}: AtButtonPropsElement) => {
	return (
		<button
			type={type}
			style={styles}
			onClick={onClick}
			disabled={disabled}
			className={`${getButtonVariant(variant)} ${className} ${
				disabled && 'opacity-25'
			} hover:bg-primary_light`}
		>
			{children}
		</button>
	);
};

export default AtButton;
