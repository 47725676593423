import React, { SVGProps } from 'react';

const Logout = ({
	height = 20,
	width = 20,
	fill = '#FFFFFF',
	className,
}: SVGProps<SVGElement>) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 24 24'
			fill='none'
		>
			<g id='logout' clipPath='url(#clip0_392_585)'>
				<path
					id='Vector'
					d='M16.5 7.55556L15.231 8.80889L17.553 11.1111H8.4V12.8889H17.553L15.231 15.1822L16.5 16.4444L21 12L16.5 7.55556ZM4.8 5.77778H12V4H4.8C3.81 4 3 4.8 3 5.77778V18.2222C3 19.2 3.81 20 4.8 20H12V18.2222H4.8V5.77778Z'
					fill={fill}
					className={className}
				/>
			</g>
			<defs>
				<clipPath id='clip0_392_585'>
					<rect width='24' height='24' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};

export default Logout;
