import React, { SVGProps } from 'react';

const EditPencil = ({
	width = 25,
	height = 25,
	fill = 'none',
	stroke = '#023865',
}: SVGProps<SVGElement>) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 25 25'
			fill={fill}
		>
			<path
				d='M7.2998 7.5H6.2998C5.76937 7.5 5.26066 7.71071 4.88559 8.08579C4.51052 8.46086 4.2998 8.96957 4.2998 9.5V18.5C4.2998 19.0304 4.51052 19.5391 4.88559 19.9142C5.26066 20.2893 5.76937 20.5 6.2998 20.5H15.2998C15.8302 20.5 16.3389 20.2893 16.714 19.9142C17.0891 19.5391 17.2998 19.0304 17.2998 18.5V17.5'
				stroke={stroke}
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M16.2998 5.49998L19.2998 8.49998M20.6848 7.08499C21.0787 6.69114 21.2999 6.15697 21.2999 5.59998C21.2999 5.043 21.0787 4.50883 20.6848 4.11498C20.291 3.72114 19.7568 3.49988 19.1998 3.49988C18.6428 3.49988 18.1087 3.72114 17.7148 4.11498L9.2998 12.5V15.5H12.2998L20.6848 7.08499Z'
				stroke={stroke}
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export default EditPencil;
