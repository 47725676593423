import { AtIcon } from '../../../../components/AtIcon';
import { AtSidebarItemProps } from './types';

const AtSidebarItem = ({
	icon,
	label,
	className,
	classNameIcon,
	classNameLabel,
	isActive = false,
	isChild = true,
}: AtSidebarItemProps) => {
	const activeSidebarItemClasses = 'bg-white/[8%]';
	const activeSidebarLabelClasses = 'font-bold';
	const childSidebarClasses = 'pl-6';

	return (
		<div
			className={`sidebar-item sidebar-title flex items-center py-3 px-4 cursor-pointer hover:bg-white/[8%]  ${
				isChild ? childSidebarClasses : ''
			} text-sm ${isActive ? activeSidebarItemClasses : ''} ${className}`}
		>
			{icon && (
				<div className={`${classNameIcon} icon mr-2`}>
					<AtIcon type={icon} />
				</div>
			)}

			<div
				className={`${classNameLabel} ${
					isActive ? activeSidebarLabelClasses : ''
				} label text-white`}
			>
				{label}
			</div>
		</div>
	);
};

export default AtSidebarItem;
