import React, { SVGProps } from 'react';

const ArrowLeft = ({
	width = 20,
	height = 20,
	fill = '#023865',
}: SVGProps<SVGElement>) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 20 20'
			fill='none'
		>
			<g id='Arrow_left'>
				<path
					id='Vector 9'
					d='M3.33301 10L2.80268 9.46967L2.27235 10L2.80268 10.5303L3.33301 10ZM15.833 10.75C16.2472 10.75 16.583 10.4142 16.583 10C16.583 9.58579 16.2472 9.25 15.833 9.25V10.75ZM7.80268 4.46967L2.80268 9.46967L3.86334 10.5303L8.86334 5.53033L7.80268 4.46967ZM2.80268 10.5303L7.80268 15.5303L8.86334 14.4697L3.86334 9.46967L2.80268 10.5303ZM3.33301 10.75H15.833V9.25H3.33301V10.75Z'
					fill={fill}
				/>
			</g>
		</svg>
	);
};

export default ArrowLeft;
