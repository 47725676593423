import { CSSProperties, ButtonHTMLAttributes } from 'react';

export enum AtButtonVariants {
	PRIMARY = 'primary',
	SECONDARY = 'secondary',
	PRIMARY_OUTLINED = 'primary_outlined',
}

export enum Target {
	SELF = '_self',
	BLANK = '_blank',
}

export enum AtButtonActionType {
	OPEN_URL = 'Open URL',
	GO_BACK = 'Go back',
	SCROLL_TO = 'Scroll to',
}

export interface AtButtonProps {
	label?: string;
	variant?: string;
	disabled?: boolean;
	target?: Target;
	actionType?: AtButtonActionType;
	actionValue?: string;
	iconType?: string;
	styles?: CSSProperties;
	labelClassname?: string;
	className?: string;
	iconClasses?: string;
}

export interface AtButtonPropsElement
	extends AtButtonProps,
		ButtonHTMLAttributes<HTMLButtonElement> {}
