import { createContext, useContext, useState } from 'react';
import { fetchWithToken } from '../../utils/fetch';
import { DASHBOARD_HTTPS_CODES } from '../../views/Dashboard/types';
import { Dashboard } from './types';

const dashboardContext = createContext<any>([]);

interface DashboardProviderProps {
	children: React.ReactNode;
}
export const useDashboardContext = () => {
	return useContext(dashboardContext);
};

export const DashboardProvider = ({ children }: DashboardProviderProps) => {
	const [dashboards, setDashboards] = useState<Dashboard[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const getDashboards = async () => {
		setIsLoading(true);
		const res = await fetchWithToken('/dashboards');

		if (res?.status === DASHBOARD_HTTPS_CODES.INVALID_TOKEN) {
			alert('INVALID TOKEN, PLEASE LOG IN AGAIN');
		}

		if (res?.status === DASHBOARD_HTTPS_CODES.SUCCESS) {
			setDashboards(res?.data);
		}
		setIsLoading(false);

		return res;
	};

	return (
		<dashboardContext.Provider value={{ dashboards, isLoading, getDashboards }}>
			{children}
		</dashboardContext.Provider>
	);
};
