import React, { SVGProps } from 'react';

const House = ({
	width = 20,
	height = 20,
	fill = 'none',
	className,
}: SVGProps<SVGElement>) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 20 20'
			fill={fill}
		>
			<g id='Frame'>
				<path
					id='Vector'
					d='M2.5 7.5L10 1.66666L17.5 7.5V16.6667C17.5 17.1087 17.3244 17.5326 17.0118 17.8452C16.6993 18.1577 16.2754 18.3333 15.8333 18.3333H4.16667C3.72464 18.3333 3.30072 18.1577 2.98816 17.8452C2.67559 17.5326 2.5 17.1087 2.5 16.6667V7.5Z'
					stroke='white'
					strokeWidth='1.16667'
					strokeLinecap='round'
					strokeLinejoin='round'
					className={className}
				/>
				<path
					id='Vector_2'
					d='M7.5 18.3333V10H12.5V18.3333'
					stroke='white'
					strokeWidth='1.16667'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
			</g>
		</svg>
	);
};

export default House;
