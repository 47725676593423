import { Link, useNavigate } from 'react-router-dom';
import DatavisionLogo from '../../../../images/datavision-logo.svg';
import AtSidebarItem from '../AtSidebarItem';
import { MlSidebarProps } from './types';
import { Dashboard } from '../../../../context/DashboardsProvider/types';
import Cookies from 'universal-cookie';

const MlSidebar = ({ dashbboards, dashboardId }: MlSidebarProps) => {
	const cookies = new Cookies();
	const navigate = useNavigate();

	// Handlers
	const getActiveItem = (dashboard: Dashboard, dashboardId: string) => {
		return dashboardId === undefined
			? true
			: dashboardId === dashboard?.report?.id.toString();
	};

	const handleLogOut = () => {
		cookies.remove('jwt_token');
		navigate('/');
	};

	return (
		<div className='sidebar bg-primary w-[20%] max-w-[240px] relative'>
			<div className='logo flex justify-center mt-6'>
				<img src={DatavisionLogo} alt='CMVC logo' className='max-w-[10rem] ' />
			</div>

			<div className='sidebar-items mt-10'>
				<div className='sidebar-group overflow-y-auto h-[calc(100vh-152px)]'>
					{dashbboards &&
						dashbboards.map((dashboard) => (
							<Link
								to={`/dashboard/${dashboard?.report?.id.toString()}`}
								key={dashboard?.report?.id}
							>
								<AtSidebarItem
									label={dashboard?.report?.name}
									icon='paper'
									isActive={getActiveItem(dashboard, dashboardId ?? '1')}
									isChild={false}
								/>
							</Link>
						))}
				</div>
			</div>

			<div
				className='absolute bottom-0 w-full bg-secondary'
				onClick={handleLogOut}
			>
				<AtSidebarItem
					className='!font-bold'
					label='Log Out'
					icon='logout'
					isChild={false}
				/>
			</div>
		</div>
	);
};

export default MlSidebar;
