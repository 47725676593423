import { Dashboard } from '../context/DashboardsProvider/types';

export const getSelectedDashboard = (
	dashboards: Dashboard[],
	dashboardId: string
) => {
	const dashboard = dashboards.filter(
		(dashboard) => dashboard?.report?.id.toString() === dashboardId?.toString()
	);

	return dashboard ? dashboard[0] : undefined;
};
