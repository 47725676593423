export interface MicrosoftLoginDataProps {
	accessToken: string;
	account: {
		environment: string;
		homeAccountId: string;
		idTokenClaims: {
			aio: string;
			aud: string;
			exp: number;
			iat: number;
			iss: string;
			name: string;
			nbf: number;
			nonce: string;
			oid: string;
			preferred_username: string;
			rh: string;
			sub: string;
			tid: string;
			uti: string;
			ver: string;
		};
		localAccountId: string;
		name: string;
		tenantId: string;
		username: string;
		authority: string;
		code: any;
		correlationId: string;
		expiresOn: string;
		extExpiresOn: string;
		familyId: string;
		fromCache: boolean;
		fromNativeBroker: boolean;
		idToken: string;
		msGraphHost: string;
		requestId: string;
		scopes: string[];
		state: string;
		tokenType: string;
		uniqueId: string;
	};
}

export enum LOGIN_HTTP_CODES {
	SUCCESS = 200,
	TOKEN_EXPIRED = 403,
	USER_NOT_EXIST = 400,
}
