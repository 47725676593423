import React, { SVGProps } from 'react';

const UserOutline = ({
	width = 20,
	height = 20,
	fill = 'none',
}: SVGProps<SVGElement>) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 20 20'
			fill={fill}
		>
			<g id='Frame'>
				<path
					id='Vector'
					d='M16.6666 17.5V15.8333C16.6666 14.9493 16.3154 14.1014 15.6903 13.4763C15.0652 12.8512 14.2173 12.5 13.3333 12.5H6.66659C5.78253 12.5 4.93468 12.8512 4.30956 13.4763C3.68444 14.1014 3.33325 14.9493 3.33325 15.8333V17.5'
					stroke='white'
					strokeWidth='1.16667'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					id='Vector_2'
					d='M10.0001 9.16667C11.841 9.16667 13.3334 7.67428 13.3334 5.83333C13.3334 3.99238 11.841 2.5 10.0001 2.5C8.15913 2.5 6.66675 3.99238 6.66675 5.83333C6.66675 7.67428 8.15913 9.16667 10.0001 9.16667Z'
					stroke='white'
					strokeWidth='1.16667'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
			</g>
		</svg>
	);
};

export default UserOutline;
