import React, { SVGProps } from 'react';

const InstagramLogo = ({
	height = 12,
	width = 13,
	fill = '#818181',
}: SVGProps<SVGElement>) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 12 13'
			fill='none'
		>
			<path
				id='Vector'
				d='M10.6582 3.32069V2.1472C10.6582 1.94707 10.5582 1.84701 10.358 1.84701H9.21185C9.01172 1.84701 8.91165 1.94707 8.91165 2.1472V3.32069C8.91165 3.50263 9.01172 3.59359 9.21185 3.59359H10.358C10.5582 3.59359 10.6582 3.50263 10.6582 3.32069ZM1.6251 11.1803H10.358C10.5582 11.1803 10.6582 11.0803 10.6582 10.8801V5.94057H9.43017C9.46656 6.1589 9.48475 6.34993 9.48475 6.51367C9.48475 7.47793 9.14362 8.3012 8.46136 8.98346C7.7791 9.66572 6.95584 10.0069 5.99158 10.0069C5.02731 10.0069 4.20405 9.66572 3.52179 8.98346C2.83953 8.3012 2.4984 7.47793 2.4984 6.51367C2.4984 6.31354 2.51659 6.12251 2.55298 5.94057H1.3522V10.8801C1.3522 11.0803 1.44317 11.1803 1.6251 11.1803ZM7.65629 4.87625C7.65629 4.87625 7.54258 4.76254 7.31516 4.53512C7.08774 4.30769 6.6511 4.19398 6.00522 4.19398C5.35935 4.19398 4.80899 4.4214 4.35415 4.87625C3.89931 5.33109 3.67189 5.8769 3.67189 6.51367C3.67189 7.15045 3.89931 7.69626 4.35415 8.1511C4.80899 8.60594 5.35935 8.83336 6.00522 8.83336C6.6511 8.83336 7.20145 8.60594 7.65629 8.1511C8.11113 7.69626 8.33856 7.15045 8.33856 6.51367C8.33856 5.8769 8.11113 5.33109 7.65629 4.87625ZM10.6582 0.700807C10.9857 0.700807 11.2632 0.814517 11.4906 1.04194C11.718 1.26936 11.8317 1.53771 11.8317 1.84701V11.1803C11.8317 11.4896 11.718 11.758 11.4906 11.9854C11.2632 12.2128 10.9857 12.3265 10.6582 12.3265H1.3522C1.02471 12.3265 0.747262 12.2128 0.519842 11.9854C0.292421 11.758 0.178711 11.4896 0.178711 11.1803V1.84701C0.178711 1.53771 0.292421 1.26936 0.519842 1.04194C0.747262 0.814517 1.02471 0.700807 1.3522 0.700807H10.6582Z'
				fill={fill}
				fillOpacity='0.4'
			/>
		</svg>
	);
};

export default InstagramLogo;
